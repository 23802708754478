import React from 'react'
import { graphql } from 'gatsby'
import { pluckMetaValueFromShopifyProduct, getShopifyGatsbyImageData } from '../../utils'
import { Shop as ShopPageComponent } from '../../components/pages'
import { ShopItemType, ShopifyVariant, ShopifyMetafield } from '../../types'

const Shop: React.FC<Props> = ({ data, location }) => {
  const parsedAndFilteredShopItems: ShopItemType[] = data.allShopifyProduct.nodes.map((shopItem: any): ShopItemType => {
    return {
      slug: shopItem.handle,
      title:
        shopItem.productType === 'BSR Releases'
          ? pluckMetaValueFromShopifyProduct(shopItem, 'artists')
          : pluckMetaValueFromShopifyProduct(shopItem, 'title'),
      subtitle:
        shopItem.productType === 'BSR Releases' ? pluckMetaValueFromShopifyProduct(shopItem, 'title') : undefined,
      gatsbyImageData: getShopifyGatsbyImageData(shopItem),
      productType: shopItem.productType,
      variants: shopItem.variants,
      latest: shopItem.tags.includes('latest'),
      metafields: shopItem.metafields,
      minimumPrice: shopItem.priceRangeV2.minVariantPrice.amount,
      maximumPrice: shopItem.priceRangeV2.maxVariantPrice.amount
    }
  })

  const latestItems = parsedAndFilteredShopItems.filter((shopItem) => shopItem.latest)
  const regularItems = parsedAndFilteredShopItems.filter((shopItem) => !shopItem.latest)

  return <ShopPageComponent isShop title="Print" shopItems={[...latestItems, ...regularItems]} location={location} />
}

export default Shop

export const query = graphql`
  query PrintPageQuery {
    allShopifyProduct(filter: { productType: { in: ["Print"] } }, sort: { fields: createdAt, order: DESC }) {
      nodes {
        handle
        productType
        title
        createdAt
        tags
        priceRangeV2 {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        variants {
          shopifyId
          price
          title
          compareAtPrice
        }
        metafields {
          namespace
          key
          value
        }
        images {
          gatsbyImageData(layout: CONSTRAINED, width: 512)
        }
      }
    }
  }
`

interface Props {
  data: {
    allShopifyProduct: {
      nodes: ShopifyProductNode[]
    }
  }
  location: any
}

interface ShopifyProductNode {
  handle: string
  productType: string
  title: string
  createdAt: string
  tags: string
  variants: ShopifyVariant[]
  metafields: ShopifyMetafield[]
  images: any[]
}
